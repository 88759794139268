import React, { useEffect, useMemo } from 'react';
import T from 'prop-types';
import get from 'lodash.get';
import { Masonry } from 'masonic';

import {
  SectionHeader,
  SectionHeadline,
  SectionTitle,
  SectionSuptitle,
  SectionBody,
  sectionCardGroupHeaderGrid,
  SectionCardGroup
} from '../styles/section';
import useMediaAware from '../utils/media-query-aware-hook';
import { prepareAuthorProperties } from '../utils/utils';
import { MasonryItemsListElement } from '../styles/masonry';
import { CardBlog, CardProject } from './cards';

const latestItemsRenderFn = ({ index, data }) => {
  const { post } = data;
  const { slug } = post;
  const { title, media } = post.frontmatter;
  const cardImage = get(media, 'card.url.childImageSharp.fluid');

  if (post.internal.type === 'Blog') {
    const { excerpt, date, timeToRead } = post;
    const { topics, authors, teaser } = post.frontmatter;

    const authorsFields = prepareAuthorProperties(authors);

    return (
      <CardBlog
        title={title}
        slug={slug}
        readTime={timeToRead}
        cardImage={cardImage}
        authors={authorsFields}
        topics={topics}
        date={date}
        excerpt={teaser || excerpt}
        index={index + 1}
      />
    );
  }

  if (post.internal.type === 'Project') {
    const { client } = post.frontmatter;
    return (
      <CardProject
        title={title}
        slug={slug}
        cardImage={cardImage}
        client={client}
        index={index + 1}
        skin='dark'
      />
    );
  }

  return null;
};

export default function SectionRelated(props) {
  const { related } = props;
  const [ref, media] = useMediaAware();

  const relatedContent = useMemo(
    () => related.filter((p) => !!p.post),
    [related]
  );

  // In this case our reference is the body, and not a specific element.
  useEffect(() => {
    ref(document.body);
  }, [ref]);

  return (
    <SectionCardGroup>
      <SectionHeader grid={sectionCardGroupHeaderGrid}>
        <SectionHeadline>
          <SectionTitle>Related content</SectionTitle>
          <SectionSuptitle>More for you</SectionSuptitle>
        </SectionHeadline>
      </SectionHeader>
      <SectionBody>
        <Masonry
          overscanBy={Infinity}
          items={relatedContent}
          columnGutter={
            media
              ? media.isXsmallDown()
                ? 16
                : media.isLargeDown()
                ? 32
                : 48
              : null
          }
          columnCount={media ? (media.isSmallDown() ? 1 : 2) : null}
          columnWidth={288}
          as={MasonryItemsListElement}
          itemAs='li'
          itemKey={(item) => item.post.id}
          render={latestItemsRenderFn}
        />
      </SectionBody>
    </SectionCardGroup>
  );
}

SectionRelated.propTypes = {
  related: T.array
};
